import React, { useState } from "react";
import firebase from "gatsby-plugin-firebase";
import { Button, Modal } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import "./custom-header-bg.css"

const Contact = () => {
  const emptyData = {
    YourName: "",
    CompanyName: "",
    City: "",
    PhoneIsd: "",
    PhoneNo: "",
    Email: "",
    Subject: "",
    Message: "",
  }
  const [data, setData] = useState(emptyData)
  const [show, setModalShow] = useState(false);

  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  const handleChange = e =>
    setData({ ...data, [e.target.name]: e.target.value })

  const handleSubmit = (e) => {
    //console.log('handleSubmit')
    if (typeof window !== `undefined`) {
      e.preventDefault();
      let myForm = document.getElementById("general-contact");
      let formData = new FormData(myForm);
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          console.log("Form successfully submitted to netlify");
          //console.log(data);
          data["created"] = firebase.firestore.Timestamp.now();
          firebase
            .firestore()
            .collection('master-contacts')
            .add({ email: data["Email"], type: "general-contact", created: data["created"] })
            .then(() => {
              console.log("master-contacts: Form successfully saved in firestore")
            })
            .catch((error) => {
              console.error("Error adding document to master-contacts: ", error);
            });
          firebase
            .firestore()
            .collection('general-contacts')
            .add(data)
            .then(() => {
              console.log("general-contacts: Form successfully saved in firestore")
            })
            .catch((error) => {
              console.error("Error adding document to general-contacts: ", error);
            });
          setData(emptyData);
          setModalShow(true);
        })
        .catch((error) => alert(error));
    }
  };
  
  return (
    <>
      <PageWrapper>
        <div className="custom-header-background pt-16 pb-12 pt-lg-22 pb-lg-27">
          <div className="container">
            <div className="row justify-content-center mt-14">
              <div className="col-xxl-6 col-xl-7 col-lg-8">
                <h2 className="font-size-9 text-center mb-11 text-white">Contact Us</h2>
                <div className="bg-white px-9 pt-9 pb-7 shadow-8 rounded-4">
                  <form
                    id="general-contact"
                    name="general-contact"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={handleSubmit}
                  >
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="general-contact" />
                    <div className="row">
                      <div className="col-lg-12 mb-7">
                        <label
                          htmlFor="YourName"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                          style={{ width: "100%" }}
                        >
                          Your Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Your Name"
                            id="YourName"
                            name="YourName"
                            value={data.YourName}
                            onChange={handleChange}
                            required
                          />
                      </div>
                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="CompanyName"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          Your Company Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Your Company Name"
                            id="CompanyName"
                            name="CompanyName"
                            value={data.CompanyName}
                            onChange={handleChange}
                            required
                          />
                      </div>
                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="City"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          City
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            id="City"
                            name="City"
                            value={data.City}
                            onChange={handleChange}
                            required
                          />
                      </div>
                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="YourPhone"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          Your Phone
                        </label>
                        <br />
                          <input
                            type="text"
                            className="form-control"
                            placeholder="###"
                            id="PhoneIsd"
                            name="PhoneIsd"
                            value={data.PhoneIsd}
                            onChange={handleChange}
                            style={{ float: "left", width: "25%" }}
                            required
                          />
                          <span style={{ float: "left", width: "5%", textAlign: "center", paddingTop: "0.75rem" }}> - </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            id="PhoneNo"
                            name="PhoneNo"
                            value={data.PhoneNo}
                            onChange={handleChange}
                            style={{ float: "left", width: "70%" }}
                            required
                          />
                      </div>
                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="Email"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          E-mail
                        </label>
                        <input
                            type="email"
                            className="form-control"
                            placeholder="example@gmail.com"
                            id="Email"
                            name="Email"
                            value={data.Email}
                            onChange={handleChange}
                            required
                          />
                      </div>
                      <div className="col-lg-12 mb-7">
                        <label
                          htmlFor="Subject"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                          style={{ width: "100%" }}
                        >
                          Subject
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Subject"
                            id="Subject"
                            name="Subject"
                            value={data.Subject}
                            onChange={handleChange}
                            required
                          />
                      </div>
                      <div className="col-lg-12 mb-7">
                        <label
                          htmlFor="Message"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                          style={{ width: "100%" }}
                        >
                          Message
                        </label>
                        <textarea
                            id="Message"
                            placeholder="Type your message"
                            className="form-control h-px-144"
                            name="Message"
                            value={data.Message}
                            onChange={handleChange}
                            required
                          ></textarea>
                      </div>
                      <div className="col-lg-12 pt-4">
                        <button
                          type="submit"
                          className="btn btn-orange text-uppercase w-100 h-px-48"
                        >
                          Send Now
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="mt-8">
                    <h3 className="font-size-4">Our Contact Details</h3>
                    <div className="media mb-2">
                      <div className="mr-6">
                        <i className="fas fa-envelope mt-2"></i>
                      </div>
                      <p className="font-size-4 mb-0">info@e-cto.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Thank you!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Your form submission has been received.</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </PageWrapper>
    </>
  );
};
export default Contact;
